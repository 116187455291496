.chat-header {
  background-color: rgb(106, 92, 194);
  color: white;
  padding: 15px;
  border-bottom: 2px solid #fff;
  width: 100%;
}

.text-container {
  text-align: center;
}

.text-container span {
  color: white;
  font-size: 1em;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.text-container {
  animation: slideIn 0.6s ease-out;
}
.online {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
.online-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgb(122, 226, 131);
  margin-right: 5px;
}
.austin {
  font-weight: bold;
  margin-bottom: 5px;
}
