.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  width: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
}

.message-list {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.message-list .message-wrapper {
  display: flex;
}

.message {
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 20px;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  box-shadow: 0 0 5px rgba(148, 148, 148, 0.1);
  display: inline-block;
  width: 400px;
}

.message.user {
  background-color: rgb(106, 92, 194);
  color: white;
  align-self: flex-start;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.message.assistant {
  background-color: rgb(255, 255, 255);
  color: black;
}

.wrapper-assistant {
  margin-left: auto;
  margin-right: 20%;
}

.wrapper-user {
  margin-right: auto;
  margin-left: 20%;
}

.message-input {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.message-input input {
  width: 80%;
  padding: 15px;
  border-radius: 50px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.message-input input:focus {
  outline: none;
}

.message-input button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(106, 92, 194);
  border: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 20px;
}

.message-input button:disabled {
  cursor: not-allowed;
  background-color: lightgrey;
}

.message-input button .message {
  transition: transform 0.3s ease-in-out;
  animation: fadeIn 1.5s forwards;
}

.message.user {
  transform: translateX(10px);
}

.message.assistant {
  transform: translateX(-10px);
}

.temporarily-unavailable {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.temporarily-unavailable p {
  margin: 0;
}
.dot {
  opacity: 0;
  animation: fadeInDot 1.5s infinite;
  display: inline-block;
}

.dot:nth-child(1) {
  animation-delay: 0s;
  display: inline-block;
  margin-left: 3px;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes fadeInDot {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@media (max-width: 1200px) {
  .message-list {
    width: 80%;
  }
}

@media (max-width: 992px) {
  .message-list {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .message-list {
    width: 95%;
  }
  .message {
    width: 250px;
  }
  .wrapper-assistant {
    margin-left: auto;
  }

  .wrapper-user {
    margin-right: auto;
  }
}

@media (max-width: 576px) {
  .message-list {
    width: 100%;
  }
}
