.footer {
  background-color: #fff;
  padding: 5px;
  text-align: center;
  border-top: 1px solid #ddd;
  width: 100%;
  margin-top: auto;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  margin: 0 10px;
  color: rgb(106, 92, 194);
  text-decoration: none;
  font-size: 11px;
}

.footer-links a:hover {
  text-decoration: underline;
}
