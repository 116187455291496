.privacy-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.privacy-page {
  width: 600px;

  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.privacy-page h1 {
  font-size: 2em;
  color: #444;
  margin-bottom: 20px;
  text-align: center;
}

.privacy-page section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-left: 4px solid #4a90e2;
  border-radius: 5px;
}

.privacy-page h2 {
  font-size: 1.5em;
  color: #4a90e2;
  margin-top: 0;
}

.privacy-page p {
  margin: 0 0 10px;
}

.privacy-page ul {
  padding-left: 20px;
  list-style-type: disc;
}

.privacy-page li {
  margin-bottom: 5px;
}
.privacy-page .back-home-link {
  display: block;
  margin-bottom: 20px;
  color: #4a90e2;
  text-decoration: none;
}
